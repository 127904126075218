<template>
  <v-main id="contact-content" class="transparent">
    <v-layout column align-center justify-center>
      <h1 class="font-weight-medium display-2 text-center">Contact</h1>
      <v-icon style="transform: scale(3,1)" class="mb-6">mdi-color-helper</v-icon>
      <v-card color="rgb(255, 0, 0, 0.0)" flat class="mx-auto" width="75%">
      </v-card>
      <template>
        <v-container>
           <v-layout row wrap justify-center class="my-12 mx-auto">
            <v-flex xs12 sm10 md8 lg6 xl6 offset-xl="0" class="my-4">
              <form>
                <v-text-field
                  v-model="name"
                  :error-messages="nameErrors"
                  :counter="80"
                  label="Name"
                  required filled
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()">
                </v-text-field>
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  :counter="320"
                  class="dark"
                  label="E-mail"
                  required filled
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()">
                </v-text-field>
                <v-textarea
                  v-model="message"
                  :error-messages="messageErrors"
                  :counter="1000"
                  label="Message"
                  required
                  filled
                  auto-grow
                  @input="$v.message.$touch()"
                  @blur="$v.message.$touch()">
                </v-textarea>
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-btn :loading="sendButtonDisabled" :disabled="sendButtonDisabled" class="mr-2 mt-2 secondary" @click="submit" :elevation="hover ? 6 : 3">submit</v-btn>
                  </template>
                </v-hover>
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-btn class="mr-6 mt-2 secondary" @click="clear" :elevation="hover ? 6 : 3">clear</v-btn>
                  </template>
                </v-hover>
                <p :class="[successLabelVisible ? 'is-visible' : 'is-not-visible']" class="title d-inline" style="position: relative; top: 7px;">Message Sent!</p>
                <p :class="[failureLabelVisible ? 'is-visible' : 'is-not-visible']" class="title" href="https://www.linkedin.com/in/danylo-pavlov-38397b15b/"
                  style="position: relative; top: 20px;">
                  Sorry, the message could not be sent, please contact me on my <a href="https://www.linkedin.com/in/danylo-pavlov-38397b15b/">LinkedIn</a>
                </p>
              </form>
           </v-flex>
          </v-layout>
        </v-container>
      </template>
    </v-layout>
  </v-main>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(80) },
    email: { required, email, maxLength: maxLength(320) },
    message: { required, maxLength: maxLength(1000) }
  },
  data: () => ({
    name: '',
    email: '',
    message: '',
    successLabelVisible: false,
    failureLabelVisible: false,
    sendButtonDisabled: false
  }),
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.maxLength && errors.push('Email can\'t be longer than 320 characters')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    messageErrors () {
      const errors = []
      if (!this.$v.message.$dirty) return errors
      !this.$v.message.maxLength && errors.push('Under 1000 characters please')
      !this.$v.message.required && errors.push('Message is required')
      return errors
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sendButtonDisabled = true
        axios
          .get(process.env.VUE_APP_EMAIL_SERVICE, {
            params: {
              fromEmail: this.email,
              name: this.name,
              message: this.message
            }
          })
          .then(response => {
            this.sendButtonDisabled = false
            if (response.status === 200) {
              this.successLabelVisible = true
              setTimeout(() => {
                this.successLabelVisible = false
              }, 5000)
            } else {
              this.failureLabelVisible = true
              setTimeout(() => {
                this.failureLabelVisible = false
              }, 10000)
            }
          }, () => {
            this.failureLabelVisible = true
            setTimeout(() => {
              this.failureLabelVisible = false
            }, 10000)
          })
        this.clear()
      }
    },
    clear () {
      this.$v.$reset()
      this.name = ''
      this.email = ''
      this.message = ''
      this.successLabelVisible = false
      this.failureLabelVisible = false
    }
  }
}
</script>

<style>
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 0.4) !important;
}
.theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
    background: rgba(0, 0, 0, 0.4) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 10000s ease-in-out 0s;
}
#contact-content .theme--dark.v-input:not(.v-input--is-disabled) input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
#contact-content .theme--light.v-input:not(.v-input--is-disabled) input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}
#contact-content .is-visible {
  visibility: visible;
}
#contact-content .is-not-visible {
  visibility: hidden;
}
@media only screen and (max-width: 365px) {
  #contact-content .title {
    font-size: 0.75rem !important;
  }
}
</style>
