<template>
  <v-main id="index-content" class="transparent hero-container">
      <vue-particles
        v-if="particleSwitch"
        color="#aaa"
        :particlesNumber="80"
        shapeType="edge"
        :particleSize="4"
        linesColor="#aaa"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push">
      </vue-particles>
      <v-layout column align-center justify-center class="hero-content">
        <h1 class="font-weight-thin mb-4 mt-6 display-3 text-center">Dan Pavlov</h1>
        <vue-typed-js
          align="center" :loop="true"
          :strings="['Full Stack Web Developer', 'Software Engineer', 'Machine Learning Developer']">
        <h2 class="xs-1 headline font-weight-light mb-6">
          I am
          <span class="typing font-weight-regular"></span>
        </h2>
        </vue-typed-js>
        <div>
          <p class="headline font-weight-light mt-6 text-center">
            Let's talk!
          </p>
        </div>
        <v-hover>
          <template v-slot="{ hover }">
            <v-btn
              class="mt-6" color="secondary" dark large :elevation="hover ? 6 : 2"
              @click="$vuetify.goTo('#about-me-content', {duration: 1000, offset: 0, easing: 'easeInOutQuad'})">
              Get to know me
            </v-btn>
          </template>
        </v-hover>
      </v-layout>
  </v-main>
</template>

<script>
import 'vue-typed-js'
import { EventBus } from '../Helpers/event-bus'

export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    particleSwitch: true
  }),
  mounted: function () {
    EventBus.$on('switchParticles', () => {
      this.particleSwitch = !this.particleSwitch
    })
  }
}
</script>

<style scoped>
.hero-container {
  height: 500px;
  margin-top: 56px;
  padding: 0 !important;
}
.hero-content {
  margin-top: 90px;
}
#particles-js {
  width: 100%;
  height: 500px;
  position: absolute;
  margin-left: -20px;
}
</style>
