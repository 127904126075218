import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: colors.teal.lighten2,
        secondary: colors.teal.lighten2,
        contentprimary: colors.grey.lighten4,
        contentsecondary: colors.grey.lighten3,
        contenttetriary: colors.grey.lighten5,
        textprimary: colors.grey.darken4,
        footer: colors.grey.lighten1
      },
      dark: {
        primary: colors.blueGrey.lighten2,
        secondary: colors.blueGrey.darken2,
        contentprimary: colors.grey.darken4,
        contentsecondary: '#313131',
        contenttetriary: colors.grey.darken3,
        textprimary: colors.grey.lighten5,
        footer: colors.grey.darken4
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
