<template>
  <div>
    <div class="chatbot-container" :class="{ open: chatbotWindowOpen, close: chatbotWindowClosed, light: !$vuetify.theme.dark }">
      <div class="chatbot-header">
        <v-icon medium color="white" class="pr-2">mdi-robot-excited</v-icon>
        <div>
          Portfolio Virtual Assistant
        </div>
        <v-btn
          elevation="0"
          fab
          x-small
          icon
          color="contentprimary"
          @click="chatbotToggle('close')"
        >
          <v-icon medium color="contentprimary" >mdi-window-minimize</v-icon>
        </v-btn>
      </div>
      <div ref="messageContainer" class="chatbot-message-container">
        <div v-for="message in chatHistory"
          :key="message.id"
        >
          <div class="chatbot-message-row"
            :class="message.senderType === senderTypes.chatbot
              ? 'bot-row' : 'guest-row'"
          >
            <div v-if="message.senderType === senderTypes.chatbot"
              class="chatbot-message-cell-icon"
            >
              <div class="pa-2 primary rounded-circle d-inline-block">
                <v-icon medium color="white">mdi-robot-excited</v-icon>
              </div>
            </div>
            <div class="chatbot-message-cell">
              <div class="chatbot-message-bubble">
                <div class="chatbot-message-title">
                  {{ message.senderName }}
                </div>
                <div class="chatbot-message-body">
                  {{ message.text }}
                </div>
              </div>
              <div class="chatbot-message-timestamp">
                {{ formatTimestamp(message.timestamp) }}
              </div>
            </div>
            <div v-if="message.senderType === 'guest'"
              class="chatbot-message-cell-icon"
            >
              <div class="pa-2 primary rounded-circle d-inline-block">
                <v-icon medium color="white">mdi-account</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chatbot-input-container">
        <textarea
          id="test123"
          ref="messageTextArea"
          v-model="currentMessageText"
          @input="textAreaHandler"
          placeholder="Type here..."
          style="" />
          <v-btn color="contentprimary" icon :loading="sendButtonLoading" @click="sendMessage(currentMessageText)">
            <v-icon color="contentprimary" medium>mdi-send</v-icon>
          </v-btn>
      </div>
    </div>
    <v-btn class="chatbot-toggle"
      :class="{ closed: !chatbotToggleOpen }"
      fab
      x-large
      color="secondary"
      @click="chatbotToggle('open')">
      <v-icon medium>mdi-chat</v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from 'axios'
import goTo from 'vuetify/es5/services/goto'
import { EventBus } from '../Helpers/event-bus'

export default {
  data: () => ({
    chatbotInitialised: false,
    chatbotWindowOpen: false,
    chatbotWindowClosed: false,
    chatbotToggleOpen: true,
    senderTypes: {
      chatbot: 'chatbot',
      guest: 'guest'
    },
    currentMessageText: '',
    sendButtonLoading: false,
    chatbotName: 'DanBot 3000',
    guestName: 'Guest',
    chatHistory: [],
    usedIntentCounter: [],
    maxLengthFirstMessage: 'Apologies, but I can help you best when you send short queries.',
    maxLengthSecondMessage: 'Maximum message length is 100 characters.',
    maxLengthMessageSent: false,
    maxLengthMessageTimedOut: false,
    keyCodes: {
      enter: 13,
      esc: 27,
      c: 67
    },
    dynoWokenUp: false,
    wakeUpMessage: 'Please wait a moment while I wake up *booting up the coffee machine*',
    welcomeMessage: 'Welcome, my name is DanBot 3000, please ask me a question!',
    serverErrorMessage: 'Apologies, there seems to be an issue with my back-end server, please refresh the page and try again. Feel free to contact Dan using the form at the bottom of the page, to inform him of this issue.'
  }),
  computed: {
  },
  watch: {
    // when window is opened for the first time, send the welcome message
    async chatbotWindowOpen () {
      if (!this.chatbotInitialised) {
        this.chatbotInitialised = true
        await this.appendMessage(this.senderTypes.chatbot, this.welcomeMessage, Date.now())
      }
    }
  },
  async created () {
    document.addEventListener('keydown', this.keyDownHandler)

    const body = { request_user_query: 'Wake up', response_number: 1 }
    const response = await axios.post(process.env.VUE_APP_CHATBOT_SERVICE, body, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*'
      }
    })

    if (response) {
      this.dynoWokenUp = true
    }

    setInterval(async () => {
      await axios.post(process.env.VUE_APP_CHATBOT_SERVICE, body, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*'
        }
      })
    }, 300000)
  },
  beforeUnload () {
    document.removeEventListener('keydown', this.keyDownHandler)
  },
  methods: {
    keyDownHandler (e) {
      if (e.repeat) { return }

      if (e.keyCode === this.keyCodes.enter && this.chatbotWindowOpen) {
        if (this.chatbotWindowOpen && e.srcElement === this.$refs.messageTextArea) {
          e.preventDefault()
          this.sendMessage(this.currentMessageText)
        }
      } else if (e.altKey && e.key === 'c') {
        e.preventDefault()
        this.chatbotToggle(this.chatbotWindowOpen ? 'close' : 'open')
      } else if (e.keyCode === this.keyCodes.esc && this.chatbotWindowOpen) {
        e.preventDefault()
        this.chatbotToggle('close')
      }

      e.stopPropagation()
    },
    chatbotToggle (state) {
      if (state === 'open') {
        this.chatbotWindowOpen = true
        this.chatbotWindowClosed = false
        this.chatbotToggleOpen = false
      } else if (state === 'close') {
        this.chatbotWindowOpen = false
        this.chatbotWindowClosed = true
        this.chatbotToggleOpen = true
      }
    },
    async sendMessage (messageText) {
      if (messageText === '' || this.sendButtonLoading) return

      await this.appendMessage(this.senderTypes.guest, messageText)

      this.currentMessageText = ''
      this.$refs.messageTextArea.style.height = '37px'

      await this.postQuery(messageText)

      this.$refs.messageTextArea.focus()
    },
    async postQuery (messageText) {
      const body = {
        request_user_query: messageText,
        response_number: 1
      }

      let wakeUpTimer
      if (!this.dynoWokenUp) {
        wakeUpTimer = setTimeout(() => {
          this.appendMessage(this.senderTypes.chatbot, this.wakeUpMessage, Date.now())
        }, 5000)
      }

      this.sendButtonLoading = true

      const sendTimestamp = Date.now()

      let response
      try {
        response = await axios
          .post(process.env.VUE_APP_CHATBOT_SERVICE, body, {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*'
            }
          })
      } catch {
        try {
          response = await axios
            .post(process.env.VUE_APP_CHATBOT_SERVICE, body, {
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
              }
            })
        } catch {
          response = false
        }
      }

      if (response.status === 200 && response.data) {
        this.dynoWokenUp = true
        clearTimeout(wakeUpTimer)

        await this.handleChatbotResponse(sendTimestamp, response.data)
      } else {
        await this.appendMessage(this.senderTypes.chatbot, this.serverErrorMessage, sendTimestamp)
      }
      this.sendButtonLoading = false
    },
    async handleChatbotResponse (sendTimestamp, response) {
      const totalResponseCount = response.response_data.length
      const intentUseCount = this.usedIntentCounter.filter((x) => x === response.intent_tag).length
      let responseToUse

      // if all responses have been used
      if (intentUseCount >= totalResponseCount) {
        // reset usedIntentCounter to 0 for that response tag
        this.usedIntentCounter = this.usedIntentCounter.filter((x) => x !== response.intent_tag)
        // pick first response from the list again
        responseToUse = response.response_data[0]
      } else {
        // pick next response from the list using intentUseCount
        responseToUse = response.response_data[intentUseCount]
      }

      // increment usedIntentCounter for that response tag by 1 and send responseToUse
      this.usedIntentCounter.push(response.intent_tag)
      await this.appendMessage(this.senderTypes.chatbot, responseToUse, sendTimestamp)

      if (response.intent_tag.includes('command')) {
        this.commandHandler(response.intent_tag)
      }
    },
    commandHandler (intentTag) {
      switch (intentTag) {
        case 'command_dark_mode':
          this.$vuetify.theme.light = false
          this.$vuetify.theme.dark = true
          break
        case 'command_light_mode':
          this.$vuetify.theme.light = true
          this.$vuetify.theme.dark = false
          break
        case 'command_particles':
          EventBus.$emit('switchParticles')
          break
        case 'command_minimise_chatbot':
          setTimeout(() => {
            this.chatbotToggle('close')
          }, 500)
          break
        case 'command_navigate_top':
          goTo('#index-content', { duration: 1000, offset: 55, easing: 'easeInOutQuad' })
          break
        case 'command_navigate_about':
          goTo('#about-me-content', { duration: 1000, offset: 55, easing: 'easeInOutQuad' })
          break
        case 'command_navigate_skills':
          goTo('#skills-content', { duration: 1000, offset: 55, easing: 'easeInOutQuad' })
          break
        case 'command_navigate_projects':
          goTo('#projects-content', { duration: 1000, offset: 55, easing: 'easeInOutQuad' })
          break
        case 'command_navigate_contact':
          goTo('#contact-content', { duration: 1000, offset: 55, easing: 'easeInOutQuad' })
          break
        default:
          break
      }
    },
    async appendMessage (sender, text, sendTimestamp = 0) {
      const newMessageId = this.chatHistory.length
      const messageObject = {
        id: newMessageId,
        senderType: sender,
        senderName: sender === this.senderTypes.chatbot ? this.chatbotName : this.guestName,
        text: text,
        timestamp: Date.now()
      }

      const timeToWait = Math.max(0, (1000 - Date.now()) + sendTimestamp) + 10
      await new Promise((resolve) => setTimeout(resolve, timeToWait))

      this.chatHistory.push(messageObject)
      await setTimeout(() => {
        this.$refs.messageContainer.scrollTo(0, this.$refs.messageContainer.scrollHeight)
      }, 10)
    },
    async textAreaHandler (e) {
      e.target.style.height = '1px'
      e.target.style.height = 3 + (e.target.scrollHeight) + 'px'

      if (this.currentMessageText.length > 100) {
        this.currentMessageText = this.currentMessageText.substring(0, this.currentMessageText.length - 1)

        if (!this.maxLengthMessageTimedOut) {
          this.maxLengthMessageTimedOut = true

          const maxLengthMessage = this.maxLengthMessageSent ? this.maxLengthSecondMessage : this.maxLengthFirstMessage
          await this.appendMessage(this.senderTypes.chatbot, maxLengthMessage, Date.now())

          this.maxLengthMessageSent = true

          await new Promise((resolve) => setTimeout(resolve, 5000))
          this.maxLengthMessageTimedOut = false
        }
      }
    },
    formatTimestamp (timestamp) {
      return new Date(timestamp).toLocaleTimeString()
    }
  }
}
</script>

<style scoped>
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 15px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 5px solid transparent;
}
*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 7px ;
}

.chatbot-toggle {
  position: fixed;
  bottom: 25px;
  left: 25px;
  transition: bottom 200ms ease 0s;
  transition-delay: 600ms;
  outline: none;
}
.chatbot-toggle.closed {
  bottom: -100px;
  transition-delay: 0ms;
}

.chatbot-container {
  width: 0px;
  height: 0px;
  opacity: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 25px;
  left: 25px;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.3);

}
.chatbot-container.open {
  animation-name: chatbot-open;
  animation-delay: 250ms;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-iteration-count: 1;
}
.chatbot-container.close {
  animation-name: chatbot-close;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
@keyframes chatbot-open {
  0%   {width: 0px; height: 0px}
  1%   {width: 0px; height: 50px}
  40%  {width: 350px; height: 50px}
  100% {width: 350px; height: 600px}
}
@keyframes chatbot-close {
  0%   {width: 350px; height: 600px}
  40%  {width: 350px; height: 50px}
  99%  {width: 0px; height: 50px}
  100% {width: 0px; height: 0px}
}

.chatbot-header {
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: #455a64;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 0.3s ease-in-out;
  z-index: 1;
}
.light > .chatbot-header {
  background-color: #4db6ac;
  color: white;
}
.chatbot-header > div {
  width: 100%;
  padding-top: 1px;
  white-space: nowrap;
}
.chatbot-header > i {
  padding-bottom: 2px;
}
.chatbot-header button {
  outline: none;
}

.chatbot-message-container {
  background-image: url('../assets/images/chatbot/chatbot_background_dark.png');
  background-size: 500px;
  flex-grow: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  color: rgba(144, 164, 174, 0.7);
  transition: color .3s ease;
  scrollbar-width: thin;
}
.light > .chatbot-message-container {
  background-image: url('../assets/images/chatbot/chatbot_background_light.png');
  color: rgb(128, 203, 196, 0.5);
}
.chatbot-message-container:hover {
  color: rgba(144, 164, 174, 1);
}
.light > .chatbot-message-container:hover {
  color: rgb(128, 203, 196, 1);
}
.chatbot-message-row {
  color: white;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
}
.light .chatbot-message-row {
  color: #1f1f1f;
}
@-moz-document url-prefix() {
  .chatbot-message-row {
    padding-right: 5px;
  }
}
.chatbot-message-cell-icon > div {
  display: flex !important;
}
.bot-row .chatbot-message-cell-icon {
  padding-left: 10px;
}
.guest-row .chatbot-message-cell-icon {
  padding-right: 5px;
}
.chatbot-message-cell {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.bot-row .chatbot-message-cell {
  align-items: flex-start;
  margin-left: 10px;
}
.guest-row .chatbot-message-cell {
  align-items: flex-end;
  margin-right: 10px;
}
.chatbot-message-bubble {
  background-color: #455a64;
  max-width: 205px;
  padding: 6px 10px;
  animation-name: bubble-animate;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
}
.light .chatbot-message-bubble {
  background-color: #26A69A;
  color: white;
}
@keyframes bubble-animate {
  0%   {opacity: 0}
  100% {opacity: 1}
}
.bot-row .chatbot-message-cell > .chatbot-message-bubble {
  border-radius: 15px 15px 15px 0px;
}
.guest-row .chatbot-message-cell > .chatbot-message-bubble {
  border-radius: 15px 15px 0px 15px;
}
.chatbot-message-timestamp {
  font-size: 12px;
  margin-top: 2px;
}
.chatbot-message-title {
  font-weight: bold;
  opacity: 0.7;
}
.chatbot-message-body {
  word-break: break-word;
}

.chatbot-input-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 200px;
  width: 100%;
  background-color: #455A64;
  border-radius: 0px 0px 8px 8px;
  padding: 10px 10px 10px 10px;
  box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 30%);
}
.light .chatbot-input-container {
  background-color: #4db6ac;
}
.chatbot-input-container > textarea {
  width: 285px;
  height: 37px;
  outline: 0;
  resize: none;
  overflow-y: hidden;
  line-height: 1.33;
  color: white;
  background-color: #263238;
  padding: 7px 8px 6px 10px;
  border-radius: 8px;
  font-size: 1rem;
  transition: box-shadow ease 200ms;
}
.light .chatbot-input-container > textarea {
  color: #1f1f1f;
  background-color: #FAFAFA;
}
.chatbot-input-container > textarea:focus {
  box-shadow: 0px 0px 6px 0px rgb(38 50 56);
}
.light .chatbot-input-container > textarea:focus {
  box-shadow: 0px 0px 6px 0px #00897B;
}
.chatbot-input-container > textarea::placeholder {
  color: white;
}
.light .chatbot-input-container > textarea::placeholder {
  color: #1f1f1f;
}
.chatbot-input-container > button {
  align-self: flex-end;
  outline: none;
}
</style>
